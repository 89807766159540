import { Directive } from "@angular/core";
import { AbstractControl, FormGroup, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";
import * as moment from "moment";

@Directive({
    selector: "[appCompareTime]",
    providers: [{ provide: NG_VALIDATORS, useExisting: CompareTimeDirective, multi: true }],
    standalone: false,
})
export class CompareTimeDirective implements Validator {
    private valFn;

    constructor() {
        this.valFn = this.compareTime();
    }

    compareTime(): ValidatorFn {
        return (control: AbstractControl) => {
            let isValid = false;
            if (control && control instanceof FormGroup) {
                const group = control as FormGroup;
                if (group.controls["StartTime"] && group.controls["EndTime"]) {
                    const startTime = new Date("1970-01-01T" + moment(group.controls["StartTime"].value, ["h:mm A"]).format("HH:mm") + "Z");
                    const endTime = new Date("1970-01-01T" + moment(group.controls["EndTime"].value, ["h:mm A"]).format("HH:mm") + "Z");
                    isValid = startTime.getTime() < endTime.getTime();
                }
            }
            if (isValid) {
                return null;
            } else {
                return { "comparetimeCheck": "failed" };
            }
        };
    }

    validate(c: AbstractControl): ValidationErrors | null {
        return this.valFn(c);
    }

}
