import { GetCustomGoalStatusDto } from "@api";

import { GoalStatus } from "~shared/enums";

export declare type IGoalStatus = GoalStatus | GetCustomGoalStatusDto;

export interface StatusWithNotes {
    status: IGoalStatus;
    notes?: string;
};

const statusOptions =
    [GoalStatus.updateRequired, GoalStatus.offTarget, GoalStatus.onTarget, GoalStatus.complete, GoalStatus.cancelled];

export const getStatusOptions = (isPastDue: boolean, cancellationEnabled: boolean): GoalStatus[] =>
    statusOptions.filter(s => {
        if (s === GoalStatus.cancelled) return cancellationEnabled;
        if (s === GoalStatus.onTarget) return !isPastDue;
        return true;
    });

export const isStandardStatus = (status: IGoalStatus): status is GoalStatus =>
    typeof status === "number";

export const isCustomStatus = (status: IGoalStatus): status is GetCustomGoalStatusDto =>
    !isStandardStatus(status);

export const getUnderlyingStatus = (status: IGoalStatus): GoalStatus =>
    isCustomStatus(status) ? status.status : status;

export const areStatusesEqual = (o1: IGoalStatus, o2: IGoalStatus) => {
    if (isStandardStatus(o1)) {
        return o1 === o2;
    }
    if (isStandardStatus(o2)) return false;
    return o1.id === o2.id;
};

export const augmentCustomStatuses = (customStatuses: GetCustomGoalStatusDto[], coreStatuses: GoalStatus[]): IGoalStatus[] => {
    const missingStatuses = coreStatuses.filter(s => !customStatuses.some(cs => cs.status === s));
    return [...missingStatuses, ...customStatuses.filter(s => coreStatuses.includes(s.status))];
};
