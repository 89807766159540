<mat-select [appDisable]="false" disableOptionCentering [formControl]="statusControl" [compareWith]="areStatusesEqual" required
    panelWidth="">
    <mat-select-trigger>
        <app-goal-status [status]="statusControl.value"></app-goal-status>
    </mat-select-trigger>
    @let dummyStatus = dummyStatus$ | async;
    @if (dummyStatus != null) {
        <!-- Dummy option to allow trigger to work properly in case the current status has been removed -->
        <mat-option class="wf-goal-status-option" disabled [value]="dummyStatus">
            <app-goal-status [status]="dummyStatus"></app-goal-status>
        </mat-option>
    }
    <mat-option class="wf-goal-status-option" *ngFor=" let status of statuses$ | async" [value]="status">
        <app-goal-status [status]="status"></app-goal-status>
    </mat-option>
</mat-select>
<button mat-icon-button *ngIf="statusNotesEnabled" [appDisable]="disabled" [disabled]="disabled"
    (click)="openNotesDialog()" [color]="hasNote ? 'primary' : undefined"
    [matTooltip]="'goals.update.addNote' | translate" class="add-notes-button"
    [attr.aria-pressed]="hasNote">
    <mat-icon>record_voice_over</mat-icon>
</button>