import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CaptureMethod, DayOfWeek, GetDailyChildNumberDto, GetDeployedChildNumberDto, NumberTargetType, SimpleCompanyTeamDto, SimpleUserDto } from "@api";
import { distinctUntilChanged, pairwise, Subscription } from "rxjs";

import { decimalValidator, greaterThanControl } from "~shared/util/custom-validators";
import { compareTeams } from "~shared/util/team-helper";
import { valueAndChanges } from "~shared/util/util";

import { hasLowerTarget, hasUpperTarget, sanitiseTarget, sanitiseWeekTargets } from "./target-helper";
import { WeekTargetsForm } from "./week-targets-helper";

export interface DeployedChildFormControls {
    team: FormControl<SimpleCompanyTeamDto>;
    owner: FormControl<SimpleUserDto | null>;
    targetLowerBound: FormControl<number | null>;
    targetUpperBound: FormControl<number | null>;
    data: FormControl<GetDeployedChildNumberDto>;
}

export type DeployedChildForm = FormGroup<DeployedChildFormControls>;

export type DeployedChildrenForm = FormGroup<{ [companyTeamId: string]: DeployedChildForm }>;

export interface ParentFormControls {
    captureMethod: FormControl<CaptureMethod>;
    owner: FormControl<SimpleUserDto | null>;
    updater: FormControl<SimpleUserDto | null>;
    targetType: FormControl<NumberTargetType>;
    targetLowerBound: FormControl<number | null>;
    targetUpperBound: FormControl<number | null>;
    weekTargets: WeekTargetsForm;
    deployedChildren: DeployedChildrenForm;
}

export const getCompanyTeamId = (team: SimpleCompanyTeamDto): string => `${team.company.id}_${team.id}`;

const determineNewUpdater = (
    newOwner: SimpleUserDto | null,
    oldOwner: SimpleUserDto | null,
    oldUpdater: SimpleUserDto | undefined,
    data: GetDeployedChildNumberDto,
): SimpleUserDto | undefined => {
    // If we are not manually updated, we must not have an updater
    if (data.captureMethod !== CaptureMethod.manual) {
        return undefined;
    }

    if (oldUpdater?.userId === oldOwner?.userId) {
        // The old updater was the same as the old owner.
        // As such, set the updater to the new owner.
        return newOwner ?? undefined;
    }

    // If we haven't delegated the item, we require an updater.
    const isUpdaterRequired = !data.delegation;
    if (isUpdaterRequired) {
        // If the updater wasn't set already, set it to the new owner.
        return oldUpdater ?? newOwner ?? undefined;
    }

    // The updater is not required. Keep whatever value was previously set.
    return oldUpdater;
};

const buildDeployedChildForm = (
    parentFormControls: ParentFormControls,
    value: GetDeployedChildNumberDto,
): [DeployedChildForm, Subscription] => {
    const companyTeam: SimpleCompanyTeamDto = {
        id: value.team.id,
        name: value.team.name,
        company: value.company,
    };

    const targetType = parentFormControls.targetType.value;
    const hasLower = hasLowerTarget(targetType);
    const hasUpper = hasUpperTarget(targetType);

    // The owner field is not normally disabled unless the whole table is disabled. As such, we assume the whole form should be disabled.
    const isDisabled = parentFormControls.owner.disabled;

    const teamControl = new FormControl({ value: companyTeam, disabled: true }, { nonNullable: true });

    const ownerControl = new FormControl<SimpleUserDto | null>(
        {
            value: value.owner ?? null,
            disabled: isDisabled,
        },
        Validators.required);

    const targetLowerBoundControl = new FormControl<number | null>(
        {
            value: value.target.lowerBound ?? null,
            disabled: isDisabled || !hasLower,
        },
        [decimalValidator]);

    const targetUpperBoundControl = new FormControl<number | null>(
        {
            value: value.target.upperBound ?? null,
            disabled: isDisabled || !hasUpper,
        },
        [decimalValidator, greaterThanControl("targetLowerBound")]);

    const dataControl = new FormControl({ value: value, disabled: true }, { nonNullable: true });

    const form: DeployedChildForm = new FormGroup({
        team: teamControl,
        owner: ownerControl,
        targetLowerBound: targetLowerBoundControl,
        targetUpperBound: targetUpperBoundControl,
        data: dataControl,
    });

    const sub = new Subscription();
    sub.add(targetLowerBoundControl.valueChanges.subscribe(() => targetUpperBoundControl.updateValueAndValidity()));
    sub.add(valueAndChanges(ownerControl).pipe(
        distinctUntilChanged(),
        pairwise(),
    ).subscribe(([oldOwner, newOwner]) => {
        // When the owner changes, as a shortcut update the updater too if it previously matched the owner.
        const data = dataControl.value;
        dataControl.setValue({
            ...data,
            owner: newOwner ?? undefined,
            updater: determineNewUpdater(newOwner, oldOwner, data.updater, data),
            dailyChildren: data.dailyChildren?.map(c => ({
                ...c,
                updater: determineNewUpdater(newOwner, oldOwner, c.updater, data),
            }))
        });
    }));

    return [form, sub];
};

const createNewDeployedChild = (team: SimpleCompanyTeamDto, weeks: number[]): GetDeployedChildNumberDto => ({
    id: "",
    globalId: "",
    company: team.company,
    team: {
        id: team.id,
        name: team.name,
    },
    captureMethod: CaptureMethod.manual,
    target: {
        lowerBound: undefined,
        upperBound: undefined,
    },
    weekTargets: sanitiseWeekTargets(weeks, NumberTargetType.withinRange, {}),
});

const enableDeployedChild = (form: DeployedChildForm, parentControls: ParentFormControls) => {
    if (parentControls.owner.enabled) form.controls.owner.enable();
    if (parentControls.targetLowerBound.enabled) form.controls.targetLowerBound.enable();
    if (parentControls.targetUpperBound.enabled) form.controls.targetUpperBound.enable();
};

export const bindDeployedChildren = (
    teams: SimpleCompanyTeamDto[],
    weeks: number[],
    parentControls: ParentFormControls,
    subscription: Subscription,
    children?: GetDeployedChildNumberDto[],
) => {
    for (const team of teams) {
        const companyTeamId = getCompanyTeamId(team);
        if (companyTeamId in parentControls.deployedChildren.controls) {
            const childForm = parentControls.deployedChildren.controls[companyTeamId];
            // If the team was previously added and removed, it will be disabled. If so, re-enable it.
            if (childForm.disabled) enableDeployedChild(childForm, parentControls);
            continue;
        }
        const value = children?.find(c => c.company.id === team.company.id && c.team.id === team.id);
        const [childForm, childSub] = buildDeployedChildForm(parentControls, value ?? createNewDeployedChild(team, weeks));
        parentControls.deployedChildren.addControl(companyTeamId, childForm);
        subscription.add(childSub);
    }

    // Disable any teams no longer being deployed to. This prevents any errors in those teams from causing validation to fail.
    for (const childForm of Object.values(parentControls.deployedChildren.controls)) {
        if (childForm.disabled) continue;
        const team = childForm.controls.team.value;
        if (teams.some(t => compareTeams(t, team))) continue;
        childForm.disable();
    }
};

export const forAllDeployed = (form: DeployedChildrenForm, callback: (child: DeployedChildForm) => void) => {
    const controls = form.controls;
    for (const companyTeamId in controls) {
        if (!Object.prototype.hasOwnProperty.call(controls, companyTeamId)) continue;
        callback(controls[companyTeamId]);
    }
};

const forAllDeployedRecursiveInternal = (
    data: GetDeployedChildNumberDto,
    callback: (child: GetDeployedChildNumberDto) => GetDeployedChildNumberDto,
): GetDeployedChildNumberDto => {
    const newData = callback(data);
    return {
        ...newData,
        deployedChildren: newData.deployedChildren?.map(c => forAllDeployedRecursiveInternal(c, callback)),
    };
};

export const forAllDeployedRecursive = (
    form: DeployedChildrenForm,
    callback: (child: GetDeployedChildNumberDto) => GetDeployedChildNumberDto,
) => {
    forAllDeployed(form, childForm => {
        const newData = forAllDeployedRecursiveInternal(childForm.controls.data.value, callback);
        childForm.controls.data.setValue(newData);
    });
};

const sanitiseDailyChildren = (
    targetType: NumberTargetType,
    weeks: number[],
    dailyUpdateDays: DayOfWeek[] | undefined,
    dailyChildren: GetDailyChildNumberDto[] | undefined,
    defaultUpdater: SimpleUserDto | undefined,
): GetDailyChildNumberDto[] | undefined => {
    if (!dailyUpdateDays || !dailyUpdateDays.length) return undefined;

    return dailyUpdateDays.map(day => {
        const dailyChild = dailyChildren?.find(c => c.updateDay === day);
        return {
            id: dailyChild?.id ?? "",
            globalId: dailyChild?.globalId ?? "",
            updateDay: day,
            updater: dailyChild?.updater ?? defaultUpdater,
            target: sanitiseTarget(targetType, dailyChild?.target ?? {}),
            weekTargets: sanitiseWeekTargets(weeks, targetType, dailyChild?.weekTargets ?? {}),
        };
    });
};

const sanitiseDeployedChild = (
    targetType: NumberTargetType,
    weeks: number[],
    dailyUpdateDays: DayOfWeek[] | undefined,
    data: GetDeployedChildNumberDto,
): GetDeployedChildNumberDto => ({
    ...data,
    dailyChildren: sanitiseDailyChildren(targetType, weeks, dailyUpdateDays, data.dailyChildren, data.updater),
    deployedChildren: data.captureMethod !== CaptureMethod.deployed || !data.deploymentDefinition ? undefined :
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        sanitiseDeployedChildren(targetType, weeks, dailyUpdateDays, data.deploymentDefinition.teams, data.deployedChildren),
    target: sanitiseTarget(targetType, data.target),
    weekTargets: sanitiseWeekTargets(weeks, targetType, data.weekTargets),
    calculationDefinition: data.captureMethod === CaptureMethod.calculated ? data.calculationDefinition : undefined,
    deploymentDefinition: data.captureMethod === CaptureMethod.deployed ? data.deploymentDefinition : undefined,
    externalData: data.captureMethod === CaptureMethod.automatic ? data.externalData : undefined,
});

const sanitiseDeployedChildren = (
    targetType: NumberTargetType,
    weeks: number[],
    dailyUpdateDays: DayOfWeek[] | undefined,
    teams: SimpleCompanyTeamDto[] | undefined,
    deployedChildren: GetDeployedChildNumberDto[] | undefined,
): GetDeployedChildNumberDto[] | undefined => {
    if (!teams || !teams.length) return undefined;

    return teams.map(team => {
        const deployedChild = deployedChildren?.find(c => c.team.id === team.id);
        if (!deployedChild) return null;
        return sanitiseDeployedChild(targetType, weeks, dailyUpdateDays, deployedChild);
    }).filter(Boolean);
};

export const getDeployedChildValue = (
    form: DeployedChildForm,
    targetType: NumberTargetType,
    weeks: number[],
    dailyUpdateDays: DayOfWeek[] | undefined,
): GetDeployedChildNumberDto => {
    const data = form.controls.data.value;
    const newData = form.getRawValue();

    return sanitiseDeployedChild(targetType, weeks, dailyUpdateDays, {
        id: data.id,
        globalId: data.globalId,
        company: data.company,
        team: data.team,
        owner: newData.owner ?? undefined,
        updater: data.updater,
        department: data.department,
        category: data.category,
        calculationDefinition: data.calculationDefinition,
        deploymentDefinition: data.deploymentDefinition,
        delegation: data.delegation,
        dailyChildren: data.dailyChildren,
        deployedChildren: data.deployedChildren,
        captureMethod: data.captureMethod,
        externalData: data.externalData,
        target: {
            lowerBound: newData.targetLowerBound ?? undefined,
            upperBound: newData.targetUpperBound ?? undefined,
        },
        weekTargets: data.weekTargets,
    });
};

export const getDeployedChildrenValue = (
    form: DeployedChildrenForm,
    teams: SimpleCompanyTeamDto[],
    targetType: NumberTargetType,
    weeks: number[],
    dailyUpdateDays: DayOfWeek[] | undefined,
) => {
    if (!teams.length) return undefined;

    return teams.map(team => {
        const companyTeamId = getCompanyTeamId(team);
        const childForm = form.controls[companyTeamId];
        return getDeployedChildValue(childForm, targetType, weeks, dailyUpdateDays);
    });
};

export const updateDeployedChildForm = (
    form: DeployedChildForm,
    value: GetDeployedChildNumberDto,
) => {
    form.controls.owner.setValue(value.owner ?? null, { emitEvent: false });
    form.controls.targetLowerBound.setValue(value.target.lowerBound ?? null, { emitEvent: false });
    form.controls.targetUpperBound.setValue(value.target.upperBound ?? null, { emitEvent: false });
    form.controls.data.setValue(value, { emitEvent: false });
};
