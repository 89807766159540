<section class="wf-section source-container">
    <div class="source-inner">
        @if (type() === "deployed") {
            <span class="source-label" translate="numbers.deployment.deployedFrom"></span>
            <h5 class="source-heading">
                {{ source().team.name }} ({{ source().company.name }})
            </h5>
        } @else {
            <span class="source-label" translate="numbers.dailyUpdates.weeklyTotal"></span> 
            <h5 class="source-heading">
                {{ source().description }}
            </h5>
        }
    </div>
    @if (canView()) {
        <button mat-icon-button type="button" [disabled]="(hasAccess$ | async) !== true" (click)="open()"
            class="source-button" [matTooltip]="'View' | translate">
            <mat-icon>launch</mat-icon>
        </button>
    }
</section>
