import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, computed, input } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { MatIconButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { MatTooltip } from "@angular/material/tooltip";
import { NumberSourceDto } from "@api";
import { TranslateModule } from "@ngx-translate/core";
import { map, Observable, of, switchMap } from "rxjs";

import { AccessService } from "~services/access.service";
import { LinkedItemViewer } from "~services/linked-item-viewer.service";
import { WithDestroy } from "~shared/mixins";
import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";
import { mapSourceToReference } from "~shared/util/number-helper";
import { shareReplayUntil } from "~shared/util/rx-operators";

@Component({
    selector: "app-number-source-header",
    imports: [
        MatIcon,
        MatIconButton,
        MatTooltip,
        TranslateModule,
        AsyncPipe,
    ],
    templateUrl: "./number-source-header.component.html",
    styleUrl: "./number-source-header.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberSourceHeaderComponent extends WithDestroy() {

    readonly source = input.required<NumberSourceDto>();

    readonly type = input.required<"deployed" | "daily">();

    readonly origin = computed(() => mapSourceToReference(this.source()));

    readonly canView = computed(() => this.linkedItemViewer.canView(this.origin()));

    readonly hasAccess$: Observable<boolean>;

    constructor(
        private readonly accessService: AccessService,
        private readonly linkedItemViewer: LinkedItemViewer,
    ) {
        super();

        this.hasAccess$ = toObservable(this.source).pipe(
            switchMap(source => {
                if (!source) return of(false);
                const { company, team } = getDelegatedItemCompanyTeam(source);
                return this.accessService.getAccessState(company.id, team.id).pipe(
                    map(a => a.canRead),
                );
            }),
            shareReplayUntil(this.destroyed$),
        );
    }

    open = () => this.linkedItemViewer.view(this.origin());
}
