import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { NumberSourceDto } from "@api";
import { TranslateModule } from "@ngx-translate/core";

import { TemplateTooltipDirective } from "~shared/directives";

@Component({
    selector: "app-number-deployment-indicator",
    imports: [
        MatIcon,
        TranslateModule,

        TemplateTooltipDirective,
    ],
    templateUrl: "./number-deployment-indicator.component.html",
    styleUrl: "./number-deployment-indicator.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberDeploymentIndicatorComponent {
    readonly source = input.required<NumberSourceDto>();
}
