import { Component, input } from "@angular/core";

@Component({
    selector: "app-video-player",
    imports: [],
    templateUrl: "./video-player.component.html",
    styleUrl: "./video-player.component.scss"
})
export class VideoPlayerComponent {
    readonly src = input.required<string>();
}
