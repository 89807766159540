import { Directive, ElementRef, HostBinding, Input, OnChanges } from "@angular/core";

// eslint-disable-next-line unused-imports/no-unused-imports
import { growAnimationBuilder } from "~shared/util/animations"; // We need this import for the doc link to work.

/**
 * A directive that can be used to give the host element height animation when transitioning between two non-zero heights.
 *
 * Note: Use of this directive requires an animation trigger called "grow" defined on the parent component.
 * This animation should accept a parameter called "startHeight" for the height of the element in px prior to the animation.
 *
 * @see {@link growAnimationBuilder} for an example of this animation.
 *
 * @example
 * // In component .ts file
 * animations: [
 *     trigger("grow", growAnimationBuilder("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
 *     // other animations here
 * ]
 *
 * // In component template
 * <div [appSmoothHeight]="isExpanded">
 *     <div *ngIf="!isExpanded">
 *         <!-- Collapsed content goes here -->
 *     </div>
 *     <div *ngIf="isExpanded">
 *         <!-- Expanded content goes here -->
 *     </div>
 * </div>
 */
@Directive({
    selector: "[appSmoothHeight]",
    standalone: false,
})
export class SmoothHeightAnimationDirective implements OnChanges {

    @Input("appSmoothHeight") smoothHeight: unknown;

    @HostBinding("@grow") get grow() {
        return { value: this.smoothHeight, params: { startHeight: this.startHeight } };
    }

    private startHeight = 0;

    constructor(private readonly element: ElementRef<HTMLElement>) { }

    ngOnChanges() {
        this.setStartHeight();
    }

    private setStartHeight = () => {
        this.startHeight = this.element.nativeElement.clientHeight;
    };

}
