import { booleanAttribute, Component, computed, input } from "@angular/core";
import { GetNumberDto, NumberRecordDetailDto } from "@api";
import { TranslateModule } from "@ngx-translate/core";

import { getDayOfWeekNameKey } from "~shared/util/translation-helper";

import { NumberDeploymentIndicatorComponent } from "../number-deployment-indicator/number-deployment-indicator.component";

@Component({
    selector: "app-number-description-column",
    imports: [
        TranslateModule,

        NumberDeploymentIndicatorComponent,
    ],
    templateUrl: "./number-description-column.component.html",
    styleUrl: "./number-description-column.component.scss",
})
export class NumberDescriptionColumnComponent {
    readonly number = input.required<GetNumberDto | NumberRecordDetailDto>();
    readonly hideUpdateDay = input(false, { transform: booleanAttribute });

    readonly isDaily = computed(() => !!this.number().source && this.number().updateDay != null);
    readonly isDeployed = computed(() => !!this.number().source && this.number().updateDay == null);

    readonly getDayOfWeekNameKey = getDayOfWeekNameKey;
}
